import { IUser } from "@Interfaces";
import {
    BUTTON_DESIGNS,
    FETCH_STATUS,
    ITemplateKey,
    isEmpty,
    useNotifications,
} from "@Utils";
import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { parseDateTime } from "@Utils/parseDateTime";
import { Button } from "../../../..";
import styles from "./notificationsPanel.module.scss";

export const NotificationsPanel = ({
    template,
    containerRef, // to handle click outside notifications container
    closeNotificationsPanel,
}: {
    template?: ITemplateKey;
    containerRef: React.RefObject<HTMLDivElement>;
    closeNotificationsPanel: () => void;
}) => {
    const {
        fetchNotificationsStatus,
        notificationsData,
        showLoadMoreButton,
        onLoadMoreClick,
    } = useNotifications({});

    return (
        <div className={styles.root}>
            <div
                className={classNames(
                    styles.container,
                    template && styles[template]
                )}
                ref={containerRef}
            >
                <div
                    className={classNames(
                        styles.header,
                        styles.uniformVerticalPadding,
                        styles.uniformHorizontalPadding
                    )}
                >
                    <div className={styles.boldText}>Notifications</div>
                    <Button
                        className={classNames(
                            styles.closeButton,
                            styles.plainTextButton
                        )}
                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                        content={<>&#10006;</>}
                        onClick={closeNotificationsPanel}
                    />
                </div>
                <div className={styles.notificationsContainer}>
                    {!isEmpty(notificationsData.data) ? (
                        <>
                            {notificationsData.data.map(
                                (notificationData: IUser.INotificationData) => {
                                    return (
                                        <div
                                            key={
                                                notificationData.user_notification_id
                                            }
                                        >
                                            <div
                                                className={classNames(
                                                    styles.uniformSmallVerticalPadding,
                                                    styles.uniformHorizontalPadding,
                                                    styles.notificationMessageContainer
                                                )}
                                                onClick={
                                                    closeNotificationsPanel
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.notificationReminder
                                                    }
                                                >
                                                    {
                                                        notificationData.notification_heading
                                                    }
                                                </div>
                                                <div
                                                    className={classNames(
                                                        styles.notificationReminder,
                                                        styles.notificationSubHeading
                                                    )}
                                                >
                                                    <span
                                                        className={
                                                            styles.notificationMessageLabel
                                                        }
                                                    >
                                                        Message -
                                                    </span>{" "}
                                                    {
                                                        notificationData.notification_sub_heading
                                                    }
                                                </div>
                                                <div
                                                    className={
                                                        styles.notificationDate
                                                    }
                                                >
                                                    {parseDateTime(
                                                        notificationData.created_at,
                                                        "MMM dd, yyyy KK:mm a"
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                            {showLoadMoreButton ? (
                                <div className={styles.loadMoreContainer}>
                                    <Button
                                        design={BUTTON_DESIGNS.ROUNDED_CORNERS}
                                        content={
                                            fetchNotificationsStatus ===
                                            FETCH_STATUS.FETCHING
                                                ? "Loading..."
                                                : "Load more"
                                        }
                                        disabled={
                                            fetchNotificationsStatus ===
                                            FETCH_STATUS.FETCHING
                                        }
                                        onClick={onLoadMoreClick}
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <div className={styles.emptyState}>
                            No Notifications to show... YAY!
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

NotificationsPanel.defaultProps = {
    template: undefined,
};
