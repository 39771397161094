import React from "react";
import { BsFillBellFill } from "@react-icons/all-files/bs/BsFillBellFill";
import { BsDot } from "@react-icons/all-files/bs/BsDot";
import styles from "./notification.module.scss";
import { INotification } from "./INotification";
import { NotificationsPanel } from "./NotificationsPanel";

export const Notification = ({
    hideComponent,
    template,
    notificationIconColorScheme,
    showNotificationsPanel,
    openNotificationsPanel,
    newNotificationReceived,
    notificationContainerRef,
    closeNotificationsPanel,
}: INotification.IProps) => {
    if (hideComponent) return null;
    return (
        <div className={styles.container} onClick={openNotificationsPanel}>
            <>
                <BsFillBellFill
                    size={20}
                    className={notificationIconColorScheme}
                />
                {newNotificationReceived ? (
                    <div className={styles.newNotificationIconContainer}>
                        <BsDot
                            className={styles.newNotificationIcon}
                            size={55}
                        />
                    </div>
                ) : null}
                {showNotificationsPanel ? (
                    <NotificationsPanel
                        template={template}
                        containerRef={notificationContainerRef}
                        closeNotificationsPanel={closeNotificationsPanel}
                    />
                ) : null}
            </>
        </div>
    );
};
